import React, {Suspense, lazy} from "react";
import Layout from './components/Layout';
import Menu from './components/Menu';

import {
  BrowserRouter as Router,
  Switch,
  Route
  
} from "react-router-dom";

const Card = lazy(() => import("./components/Card"));
const QuienesSomos = lazy(() => import("./components/QuienesSomos"));
const CarouselQuienes = lazy(() => import("./components/CarouselQuienes"));

const CarouselMain = lazy(() => import("./components/formacion_publico/CarouselMain"));
const AnimacionAudiovisual = lazy(() => import("./components/formacion_publico/animacion_audiovisual/AnimacionAudiovisual"));
const Fotogramas = lazy(() => import("./components/formacion_publico/fotogramas_sultana/Fotogramas"));
const Acordeon = lazy(() => import("./components/formacion_publico/fotogramas_sultana/Acordeon"));
const ExperimentaRural = lazy(() => import("./components/formacion_publico//experimenta_rural/ExperimentaRural"));

const CarouselExperimental = lazy(() => import("./components/cine_experimental/CarouselExperimental"));
const Irrupcion = lazy(() => import("./components/cine_experimental/irrupcion/Irrupcion"));
const Metodo = lazy(() => import("./components/cine_experimental/metodo/Metodo"));
const Rio = lazy(() => import("./components/cine_experimental/rio/Rio"));
const Carne = lazy(() => import("./components/cine_experimental/carne/Carne"));
const Queer = lazy(() => import("./components/cine_experimental/queer/Queer"));
const Divagacion = lazy(() => import("./components/cine_experimental/divagacion/Divagacion"));
const Inclusion = lazy(() => import("./components/cine_experimental/inclusion/Inclusion"));
const Martillos = lazy(() => import("./components/cine_experimental/martillos/Martillos"));
const Absentia = lazy(() => import("./components/cine_experimental/absentia/Absentia"));
const Salad = lazy(() => import("./components/cine_experimental/salad/Salad"));
const Calive = lazy(() => import("./components/cine_experimental/calive/Calive"));

const CarouselProcesos = lazy(() => import("./components/procesos/CarouselProcesos"));
const Vozpolitica = lazy(() => import("./components/procesos/voz_politica/Vozpolitica"));
const EspejoRoto = lazy(() => import("./components/procesos/espejo_roto/EspejoRoto"));
const Esteticas = lazy(() => import("./components/procesos/esteticas/Esteticas"));
const Entreverado = lazy(() => import("./components/procesos/entreverado/Entreverado"));
const Teatro = lazy(() => import("./components/procesos/teatro/Teatro"));

const CarouselFancine = lazy(() => import("./components/fancine/CarouselFancine"));
const CardFancine = lazy(() => import("./components/fancine/Card"));
const Volumen1 = lazy(() => import("./components/fancine/volumen_1/Volumen1"));
const Videoensayos = lazy(() => import("./components/fancine/Videoensayos"));

const Footer = lazy(() => import("./components/Footer"));


function App() {
  return (
    <Router>
      <Layout>
        <Menu />        
        <Switch>          
          <Route path="/formacion-publico">
            <br></br>
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <CarouselMain />
            </Suspense> 
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <AnimacionAudiovisual />
            </Suspense> 
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Fotogramas />
            </Suspense> 
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Acordeon />
            </Suspense> 
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <ExperimentaRural />
            </Suspense> 
            <br></br>
          </Route>
          <Route path="/cine-experimental-video-arte">
            <br></br>
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <CarouselExperimental />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Irrupcion />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Metodo />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Rio />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Carne />
            </Suspense>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Queer />
            </Suspense>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Divagacion />
            </Suspense>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Inclusion />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Martillos />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Absentia />
            </Suspense>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Salad />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Calive />
            </Suspense> 
          </Route>
          <Route path="/procesos-investigacion-desarrollo">
          <br></br>
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <CarouselProcesos />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Vozpolitica />
            </Suspense>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <EspejoRoto />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Esteticas />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Entreverado />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Teatro />
            </Suspense> 
          </Route>
          <Route path="/fancine">
            <br></br>
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <CardFancine />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Volumen1 />
            </Suspense>        
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Videoensayos />
            </Suspense> 
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <CarouselFancine />
            </Suspense> 
            <br></br>
          </Route>                   
          <Route path="/">
            <br></br>
            <br></br>
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <Card />
            </Suspense>            
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <QuienesSomos />
            </Suspense> 
            <br></br>
            <Suspense fallback={<h3>Cargando...</h3>}>
              <CarouselQuienes />
            </Suspense> 
            <br></br>
            
          </Route>           
        </Switch> 
        <Suspense fallback={<h3>Cargando...</h3>}>
          <Footer />
        </Suspense>                
        </Layout>    
    </Router>
  );
}

export default App;

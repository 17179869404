import React from 'react';



const Menu = () => {
    return (
        <div>
        <div className="d-none d-lg-block">
        <nav className="navbar navbar-expand-lg navbar-dark bg-danger fixed-top opacity-75 ">
            <div className=" container-fluid">
            <a className="navbar-brand shadow-lg p-1 mb-1 bg-body rounded" href="/">
                <img src="favicon.ico" alt="" width="35" height="30" />
            </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/formacion-publico"> FORMACIÓN DE PÚBLICOS</a>
                        </li>                                                               
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle" href="/formacion-publico" id="navbar1" role="button" data-bs-toggle="dropdown" aria-expanded="true">                                
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar1" >
                                <li><a className="dropdown-item bi bi-box-arrow-right"  href="/formacion-publico#animacion_audiovisual" > Laboratorio de Animación Audiovisual </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/formacion-publico#fotogramas"> Fotogramas de la Sultana Rural </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/formacion-publico#experimenta_rural"> Experimenta Rural</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/cine-experimental-video-arte">CINE EXPERIMENTAL Y VIDEO ARTE</a>
                        </li> 
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle"  href="/cine-experimental-video-arte" id="navbar2" role="button" data-bs-toggle="dropdown" aria-expanded="false">                                
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar2">
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#irrupcion"> Irrupción </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#metodo"> El Método </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#rio"> Andá Buscálo al Río </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#carne"> Carne Radioactiva </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#queer"> Futuros Queer. <br></br>Un Diálogo Para Más de Dos </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#divagacion"> Estados de Divagacion </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#inclusion"> Inclusión. This is Colombia </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#martillos"> Nuestros Martillos <br></br>Nuestras Brujas </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#absentia"> In Absentia </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#salad"> Salad </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#calive"> Cali Vé </a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/procesos-investigacion-desarrollo"> PROCESOS INVESTIGATIVOS Y EDITORIALES</a>
                        </li> 
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle" href="/procesos-investigacion-desarrollo" id="navbar3" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar3">
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#voz-politica"> La Voz Política del Arte <br></br> y su Cable a Tierra</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#espejo-roto"> Espejo Roto Espejo Enfermo</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#esteticas"> Estéticas de la violencia en el <br></br>Valle del Cauca De lo mitológico <br></br>a lo visceral</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#entreverado"> Entreverado. Manifestaciones de la <br></br>Cocina Popular Bonaverense</a></li>                                
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#teatro-griego"> Del teatro griego al cine experimental</a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/fancine">FAN_CINE Crítica de Cine y Video Experimental Colombiano</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link  active dropdown-toggle" href="/fancine" id="navbar4" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar4">
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/fancine#volumen1"> Volumen I</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/fancine#volumen2"> Volumen II</a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/fancine#videoensayos"> Videoensayos</a></li>
                            </ul>
                        </li>  
                        
                        <li className="nav-item">
                            <a href="#contactoFooter" className="nav-link active" aria-current="page" ><i className="bi bi-info-circle"> Contacto</i></a>
                        </li>


                                          
                    </ul>                
                </div>
            </div>
        </nav>  
        </div>
        <div class="d-lg-none text-white">
        <nav className="navbar navbar-expand-lg navbar-dark bg-danger fixed-top ">
            <div className=" container-fluid">
            <a className="navbar-brand shadow-lg p-1 mb-1 bg-body rounded" href="/">
                <img src="favicon.ico" alt="" width="35" height="30" />
            </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/formacion-publico"> FORMACIÓN DE PÚBLICOS</a>
                        </li>                                                               
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle" href="/formacion-publico" id="navbar1" role="button" data-bs-toggle="dropdown" aria-expanded="true">                                
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar1" >
                                <li ><a className="dropdown-item bi bi-box-arrow-right"  href="/formacion-publico#animacion_audiovisual" ><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Laboratorio de Animación Audiovisual</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/formacion-publico#fotogramas"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Fotogramas de la Sultana Rural</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/formacion-publico#experimenta_rural"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Experimenta Rural</span></a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/cine-experimental-video-arte">CINE EXPERIMENTAL Y VIDEO ARTE</a>
                        </li> 
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle"  href="/cine-experimental-video-arte" id="navbar2" role="button" data-bs-toggle="dropdown" aria-expanded="false">                                
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar2">
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#irrupcion" ><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Irrupción</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#metodo"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse">  El Método</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#rio"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Andá Buscálo al Río</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#carne"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Carne Radioactiva</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#queer"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Futuros Queer. <br></br>Un Diálogo Para Más de Dos </span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#divagacion"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Estados de Divagacion</span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#inclusion"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Inclusión. This is Colombia</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#martillos"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Nuestros Martillos <br></br>Nuestras Brujas </span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#absentia"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> In Absentia</span> </a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#salad"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Salad </span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/cine-experimental-video-arte#calive"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Cali Vé </span></a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/procesos-investigacion-desarrollo"> PROCESOS INVESTIGATIVOS Y EDITORIALES</a>
                        </li> 
                        <li className="nav-item dropdown">
                            <a className="nav-link active dropdown-toggle" href="/procesos-investigacion-desarrollo" id="navbar3" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar3">
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#voz-politica"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> La Voz Política del Arte <br></br> y su Cable a Tierra</span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#espejo-roto"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Espejo Roto Espejo Enfermo</span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#esteticas"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Estéticas de la violencia en el <br></br>Valle del Cauca De lo mitológico <br></br>a lo visceral</span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#entreverado"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Entreverado. Manifestaciones de la <br></br>Cocina Popular Bonaverense</span></a></li>                                
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/procesos-investigacion-desarrollo#teatro-griego"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Del teatro griego al cine experimental</span></a></li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/fancine">FAN_CINE Crítica de Cine y Video Experimental Colombiano</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link  active dropdown-toggle" href="/fancine" id="navbar4" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbar4">
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/fancine#volumen1"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Volumen I</span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/fancine#volumen2"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Volumen II</span></a></li>
                                <li><hr class="dropdown-divider"/></li>
                                <li><a className="dropdown-item bi bi-box-arrow-right" href="/fancine#videoensayos"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Videoensayos</span></a></li>
                            </ul>
                        </li>  
                        

                        <li className="nav-item">
                            <a href="#contactoFooter" className="nav-link active" aria-current="page" ><i className="bi bi-info-circle"><span data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse"> Contacto</span></i></a>
                        </li>                    
                    </ul>                
                </div>
            </div>
        </nav>  
        </div>
        </div>

    );
  }
  
  export default Menu;
import React from 'react';

const Layout = ({children}) => {
    return (
        <div className='bg-dark text-white'>
          {children}  
        </div>
    )
}

export default Layout